<template>
  <table v-if="report.length">
    <tr>
      <template v-for="(item, index) in tableHeader">
        <th :key="`th-${index}`">{{ item }}</th>
      </template>
    </tr>
    <template v-for="(item, index) in report">
      <tr :key="index">
        <td>{{ item.id }}</td>
        <td>
          <template v-if="item.client_name">
            {{ item.client_name }}
          </template>
          <template v-if="item.client_phone">
            <br>{{ item.client_phone }}
          </template>
        </td>
        <td>
          {{ formatDate(item.created_at, "dd.MM.yyyy") }}
        </td>
        <td>
          {{ item.delivery_type_id==1 ? "Самовывоз":"Самовывоз" }}
          <template v-if="item.date">
            <br>{{ formatDate(item.date, "dd.MM.yyyy")  }}
          </template>
          <template v-if="item.delivery_time">
            <br>{{ item.delivery_time }}
          </template>
          <template v-if="item.courier">
            <br>{{ item.courier.name }}
          </template>
        </td>
        <td>
          <template v-for="(bouquet, indexBouquet) in item.order_bouquets">
            <div :key="`good-${indexBouquet}`">
              {{ bouquet.name }} - {{ bouquet.count }}
            </div>
          </template>
        </td>
        <td>{{ item.cost }}</td>
        <td>
          <template v-if="item.order_status">
            {{ item.order_status.name }}
          </template>
        </td>
        <td>
          <template v-if="item.user">
            {{ item.user.name }}
          </template>
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
import format from "date-fns/format";
import { ru } from "date-fns/locale";

export default {
  props: {
    report: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableHeader: [
        '№',
        'Клиент',
        'Дата',
        'Доставка',
        'Состав',
        'Стоимость',
        'Статус',
        'Менеджер',
      ],
      orderBouquetsList: [],
      report: [],
    };
  },
  methods: {
    formatDate(date, dateFormat) {
      return format(new Date(date), dateFormat, { locale: ru });
    },
  },
};
</script>
