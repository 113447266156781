<template>
  <div>
    <table v-if="report.length">
      <tr>
        <template v-for="(item, index) in tableHeader">
          <th :key="`th-${index}`">{{ item }}</th>
        </template>
      </tr>
      <template v-for="(item, index) in report">
        <tr :key="index">
          <td>{{ item.id }}</td>
          <td>
            <template v-if="item.florist">
              {{ item.florist.name }}
            </template>
          </td>
          <td>
            <template v-if="item.client">
              {{ item.client.name }}
            </template>
          </td>
          <td>
            <template v-if="item.order_bouquet">
              № {{ item.order_bouquet.orderId }}
              <br>{{ item.order_bouquet.name }} - {{ item.order_bouquet.count }}
            </template>
          </td>
          <td>
            <template v-for="(goodFields, indexGood) in item.bouquet_goods">
              <div :key="`good-${indexGood}`">
                {{ goodFields.good.name }} - {{ goodFields.count }} ({{ goodFields.original_price }}р)
              </div>
            </template>
          </td>
          <td>
            <template v-if="item.created_by">
              {{ item.created_by.name }}
            </template>    
          </td>
          <td>{{ item.total_cost }}</td>
          <td>{{ formatDate(item.created_at, "dd.MM.yyyy") }}</td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import gql from "graphql-tag";
import format from "date-fns/format";
import { ru } from "date-fns/locale";

export default {
  props: {
    report: {
      type: Array,
      required: true,
    },
    // dateStart: {
    //   type: String,
    //   required: true,
    // },
    // dateEnd: {
    //   type: String,
    //   required: true,
    // },
    // goodId: {
    //   type: Number,
    //   required: true,
    // },
  },
  data() {
    return {
      tableHeader: [
        '№',
        'Флорист',
        'Клиент',
        'Заказ',
        'Состав',
        'Менеджер',
        'Стоимость',
        'Дата',
      ],
      // report: [],
    };
  },
  methods: {
    formatDate(date, dateFormat) {
      return format(new Date(date), dateFormat, { locale: ru });
    },
  },
};
</script>
